<template>
    <div class="commodity">
        <div class="top">
            <div class="title">我的足迹</div>
            <div class="policy" @click="empty">
                <i class="iconfont icondustbin_icon"></i>
                清空浏览记录
            </div>
        </div>
        <div class="commodity-main">
            <div v-for="(j, index) in list" :key="index" class="date">
                <i class="iconfont iconmiaobiao"></i> {{ j.Date }}
                <el-row :gutter="20" style="margin-top: 20px;width: 1059px">
                    <el-col :span="6" v-for="(item, i) in j.List" :key="i">
                        <div class="commodity-list" @click="handleToPage(item)">
                            <el-card
                                class="commodity-card"
                                shadow="never"
                                :body-style="{
                                    padding: '10px',
                                    height: '200px'
                                }"
                            >
                                <img
                                    :src="pic + item.Picture"
                                    class="image"
                                    style="height:250px"
                                />
                                <div class="star" @click.stop="del(item)">
                                    <img src="../../assets/empty.png" alt="" />
                                </div>
                            </el-card>
                            <div class="commodity-text">
                                <div class="name">
                                    {{ item.ProName }}
                                </div>
                                <div class="bottom">
                                    <div class="bottom-top">
                                        <div class="price">
                                            ￥{{ item.Price }}
                                        </div>
                                        <div
                                            v-if="
                                                item.PLUSPrice > 0 &&
                                                    item.Price != item.PLUSPrice
                                            "
                                            class="PLUS"
                                        >
                                            ￥{{ item.PLUSPrice }}
                                            <img
                                                src="../../assets/plus.png"
                                                @click="toplus"
                                                alt=""
                                            />
                                        </div>
                                    </div>
                                    <div class="bottom-bottom">
                                        <div>
                                            <span class="original"
                                                >￥{{ item.InTaxPrice }}</span
                                            >
                                        </div>
                                        <div class="sold">
                                            已售{{ item.VirtualSales }}件
                                        </div>
                                    </div>
                                </div>
                                <div class="border"></div>
                            </div>
                        </div>
                    </el-col>
                </el-row>
            </div>
        </div>
    </div>
</template>

<script>
import { getFootList, clearFoot } from './service'

export default {
    data() {
        return {
            pic: FILE_URL,
            list: [],
            parameter: {
                PageIndex: 1,
                PageSize: 10
            },
            count: 0
        }
    },
    methods: {
        empty() {
            this.$confirm('你确定要执行吗, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                clearFoot().then(() => {
                    this.$message.success('操作成功')
                    this.init()
                })
            })
        },
        del(data) {
            clearFoot({ ID: data.ID }).then(() => {
                this.$message.success('操作成功')
                this.init()
            })
        },
        init() {
            getFootList(this.parameter).then(res => {
                this.list = res.data
                this.count = res.count
            })
        },
        toplus() {
            if (!localStorage.getItem('userToken')) {
							this.toCIAMLoginIn()	
							// localStorage.setItem('u', '')
       //          this.$message.success('正在跳转登录')
       //          setTimeout(() => {
       //              let url = encodeURI(window.location.href)
       //              window.location.href = `${URL_LOGIN}?returnurl=${url}`
       //          }, 1000)
            } else {
                window.open(`/me/plus`, '_blank').location
            }
        },
        handleToPage(data) {
            let u = localStorage.getItem('u')
            window.open(`/commodity?pid=${data.PID}&u=${u}`, '_blank').location
        }
    },
    mounted() {
        this.init()
    }
}
</script>

<style lang="less" scoped>
.date {
    color: #666666;
    margin: 30px 0;
}

.top {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #dcdcdc;
    padding-bottom: 5px;

    .title {
        font-size: 18px;
        font-family: 'ct';
    }
    .policy {
        font-size: 12px;
        color: #999999;
        cursor: pointer;
        i {
            margin: 0 5px;
        }
    }
}

.commodity {
    .commodity-main {
        width: 1240px;
        margin: 30px auto;
    }

    .commodity-card {
        cursor: pointer;
        img {
            width: 100%;
        }
    }

    .commodity-text {
        margin-top: 20px;

        .code {
            font-size: 14px;
        }

        .name {
            font-size: 14px;
            font-family: 'nl';
            letter-spacing: 0;
            overflow: hidden;
            display: -webkit-box;
            text-overflow: ellipsis;
            -webkit-line-clamp: 1; /*要显示的行数*/
            -webkit-box-orient: vertical;
            font-size: 12px;
        }

        .sku {
            margin: 8px 0;
            font-size: 12px;
            color: #333333;
        }
        .bottom {
            .bottom-top {
                display: flex;
                .price {
                    color: #1b98aa;
                    font-family: 'ct';
                    font-size: 18px;
                }
                .PLUS {
                    color: #000000;
                    font-family: 'ct';
                    font-size: 18px;
                    margin-left: 10px;
                }
            }
            .bottom-bottom {
                display: flex;
                justify-content: space-between;
                .original {
                    color: #666666;
                    text-decoration: line-through;
                }
                .sold {
                    color: #666666;
                }
            }
        }
        .border {
            height: 2px;
            margin-top: 10px;
            background: #bbbfbe;
        }
    }

    .commodity-list {
        position: relative;
        height: 350px;

        .star {
            width: 30px;
            height: 30px;
            line-height: 30px;
            position: absolute;
            top: 20px;
            right: 20px;
            text-align: center;
            border-radius: 5px;
            display: none;

            img {
                width: 15px;
                height: 15px;
                margin-top: 7px;
            }
        }
    }

    .commodity-list:hover {
        .border {
            height: 2px;
            background: linear-gradient(50deg, #60b689, #00b5c2, #0897b4);
        }

        .star {
            display: block;
        }
    }
}
</style>
