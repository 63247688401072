import { post } from '@/http'

// 收藏
export function getFootList(data) {
    return new Promise((resolve, reject) => {
        post('/Footprint/GetFootList', data)
            .then(res => {
                resolve(res)
            })
            .catch(err => {
                reject(err)
            })
    })
}

// 收藏
export function clearFoot(data) {
    return new Promise((resolve, reject) => {
        post('/Footprint/ClearFoot', data)
            .then(res => {
                resolve(res)
            })
            .catch(err => {
                reject(err)
            })
    })
}
